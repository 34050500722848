$color_1: #777;
$color_2: #333;
$color_3: #608dfd;
$color_4: #00573f;
$color_5: #fff;
$color_6: #555;
$color_7: #FFF;
$color_8: white;
$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Raleway", sans-serif;
$font-family_3: "Century Gothic", sans-serif;
$font-family_4: "Lato", sans-serif;
$background-color_1: #fff;
$background-color_2: #5ca9fb;
$background-color_3: #6372ff;
$background-color_4: #25d366;
$border-color_1: rgba(231, 231, 231, 0);

body {
  font-family: $font-family_1;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: $color_1;
  font-weight: 400;
  min-width: 435px;
}
html {
  font-family: $font-family_1;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: $color_1;
  font-weight: 400;
  min-width: 435px;
}
h2 {
  font-family: $font-family_2;
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: $color_2;
}
h3 {
  font-family: $font-family_2;
  font-size: 20px;
  font-weight: 600;
  color: $color_2;
}
h4 {
  font-family: $font-family_2;
  font-size: 18px;
  color: $color_2;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  text-align: justify;
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: $color_3;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    color: $color_3;
  }
  &:focus {
    text-decoration: none;
    color: $color_3;
  }
}
ul {
  list-style: none;
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
ol {
  list-style: none;
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
#main-menu {
  min-width: 400px;
  padding: 15px;
  transition: all 0.8s;
  margin-bottom: 0;
  background-color: $background-color_1;
  border-color: $border-color_1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  a.navbar-brand {
    font-family: $font-family_3;
    font-size: 24px;
    font-weight: 700;
    color: $color_4;
    line-height: 45px;
  }
  div.spacing {
    height: 20px;
  }
  .navbar-nav {
    >li {
      position: relative;
      >.enroll-button {
        color: $color_5;
        background-color: $background-color_2;
        background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        margin: 3px 10px;
        text-decoration: none;
        &:hover {
          color: $color_5;
          background-image: none;
          background-color: $background-color_3;
        }
        &:focus {
          color: $color_5;
          background-image: none;
          background-color: $background-color_3;
        }
        &:active {
          color: $color_5;
          background-image: none;
          background-color: $background-color_3;
        }
      }
      >.enroll-button.focus {
        color: $color_5;
        background-image: none;
        background-color: $background-color_3;
      }
      >.enroll-button.active {
        color: $color_5;
        background-image: none;
        background-color: $background-color_3;
      }
      >a.section-button {
        --horizontal-padding: 2px;
        --horizontal-marging: 15px;
        font-family: $font-family_4;
        text-transform: uppercase;
        color: $color_6;
        font-size: 15px;
        font-weight: 400;
        padding-right: 8px var(--horizontal-padding);
        border-radius: 0;
        margin: 9px var(--horizontal-marging) 0;
        text-decoration: none;
        &:after {
          --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
          display: block;
          position: absolute;
          left: calc((100% - var(--text-width)) / 2);
          bottom: -6px;
          width: 0;
          max-width: var(--text-width);
          height: 2px;
          background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
          content: "";
          transition: width 0.2s;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #main-menu {
    div.spacing {
      display: none;
    }
  }
}
@media (max-width: 1199px) {
  #main-menu {
    div.spacing {
      height: 20px;
    }
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: $background-color_4;
  color: $color_7;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.floating-button {
  &:hover {
    color: $color_8;
  }

  &:active {
    color: $color_8;
  }

  &:focus {
    color: $color_8;
  }
}
