#conference {
  $color_1: #00573f;
  $color_2: #555;
  $color_3: #fff;
  $color_4: #5ca9fb;
  $color_5: rgba(255, 255, 255, 0.75);
  $color_6: #cc0033;
  $color_7: black;
  $color_8: rgba(255, 255, 255, 1);
  $color_9: #608dfd;
  $color_10: #777;
  $color_11: #e6e6e6;
  $color_12: #666;
  $color_13: #608dfd;
  $font-family_1: "Century Gothic", sans-serif;
  $font-family_2: "Lato", sans-serif;
  $font-family_3: "Raleway", sans-serif;
  $font-family_4: "FontAwesome";
  $background-color_1: #fff;
  $background-color_2: #608dfd;
  $background-color_3: transparent;
  $background-color_4: #5ca9fb;
  $background-color_5: #6372ff;
  $background-color_6: #a2aafa;
  $background-color_7: transparent;
  $border-color_1: rgba(231, 231, 231, 0);
  $border-color_2: #608dfd;
  $border-color_3: #608dfd;

  #conference-menu {
    min-width: 400px;
    transition: all 0.8s;
    top: 85px;

    a.navbar-brand {
      font-family: $font-family_1;
      font-size: 24px;
      font-weight: 700;
      color: $color_1;
    }

    .wrapper {
      text-align: center;
      padding-top: 4px;

      ul {
        float: unset;
        display: inline-block;
        margin: 0;
        padding: 0;
        zoom: 1;
        *display: inline;
      }

      li {
        float: left;
        padding: 2px 5px;
      }
    }
  }

  #conference-menu.navbar-default {
    background-color: $background-color_1;
    border-color: $border-color_1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    .navbar-nav {
      > li {
        position: relative;

        > .section-button {
          --horizontal-padding: 2px;
          --horizontal-marging: 15px;
          font-family: $font-family_2;
          text-transform: uppercase;
          color: $color_2;
          font-size: 15px;
          font-weight: 400;
          border-radius: 0;
          margin: 0 var(--horizontal-marging) 0;
          text-decoration: none;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -1px;
            width: 0;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .navbar-toggle {
    border-radius: 0;
  }

  .navbar-default {
    .navbar-toggle {
      &:hover {
        background-color: $background-color_1;
        border-color: $border-color_2;

        > .icon-bar {
          background-color: $background-color_2;
        }
      }

      &:focus {
        background-color: $background-color_1;
        border-color: $border-color_2;
      }
    }

    .navbar-nav {
      .active.section-button {
        background-color: $background-color_3;

        &:hover {
          background-color: $background-color_3;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }

        &:focus {
          background-color: $background-color_3;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }

        &:after {
          --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
          display: block;
          position: absolute;
          left: calc((100% - var(--text-width)) / 2);
          bottom: -6px;
          width: 100% !important;
          max-width: var(--text-width);
          height: 2px;
          background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
          content: "";
          transition: width 0.2s;
        }
      }

      > .main-active {
        > .section-button {
          background-color: $background-color_3;

          &:hover {
            background-color: $background-color_3;

            &:after {
              --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
              display: block;
              position: absolute;
              left: calc((100% - var(--text-width)) / 2);
              bottom: -6px;
              width: 100% !important;
              max-width: var(--text-width);
              height: 2px;
              background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
              content: "";
              transition: width 0.2s;
            }
          }

          &:focus {
            background-color: $background-color_3;

            &:after {
              --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
              display: block;
              position: absolute;
              left: calc((100% - var(--text-width)) / 2);
              bottom: -6px;
              width: 100% !important;
              max-width: var(--text-width);
              height: 2px;
              background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
              content: "";
              transition: width 0.2s;
            }
          }

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }
      }
    }
  }

  .section-title {
    margin-bottom: 70px;

    h2 {
      position: relative;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      font-size: 18px;
    }
  }

  .btn {
    &:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:focus {
        outline: none;
        outline-offset: unset;
      }
    }

    &:focus {
      outline: none;
      outline-offset: unset;
    }

    &:active.focus {
      outline: none;
      outline-offset: unset;
    }
  }

  .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    &:focus {
      outline: none;
      outline-offset: unset;
    }
  }

  a {
    &:focus {
      outline: none;
      outline-offset: unset;
    }
  }

  .btn.focus {
    outline: none;
    outline-offset: unset;
  }

  .btn.active.focus {
    outline: none;
    outline-offset: unset;
  }

  div.anchor {
    display: block;
    position: relative;
    top: -236px;
    visibility: hidden;
    height: 1px;
  }

  #header {
    div.anchor {
      top: 0;
    }

    a {
      text-decoration: none;
      margin-top: 20px;
    }

    .header-text {
      padding-top: 350px;
      padding-bottom: 270px;
      text-align: center;
    }

    .header {
      display: table;
      width: 100%;
      padding: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../../../public/assets/img/conference/1.jpg) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      -o-background-size: cover;

      .overlay {
        background: rgba(0, 0, 0, 0.2);
      }

      h1 {
        font-family: $font-family_3;
        color: $color_3;
        font-size: 60px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 10px;

        span {
          font-weight: 800;
          color: $color_4;
        }
      }

      @media (max-width: 500px) {
        h1 {
          font-size: 50px;
        }
      }

      h3 {
        color: white;
        font-weight: 300;
        margin-bottom: 35px;
      }

      p {
        color: $color_3;
        font-size: 22px;
        font-weight: 300;
        line-height: 30px;
        margin: 0 auto 40px;
        text-align: center;

        &.info {
          margin: 0 0 5px 0;
        }
      }

      #ticketBtn {
        margin-left: 10px;
      }
    }
  }

  #intro {
    padding: 60px 0;
    background: #f6f6f6;

    h3 {
      font-size: 22px;
      margin: 0 0 20px;
    }

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      margin: 30px 0;
      text-align: center;
      font-size: 28px;
      font-weight: 300;
    }

    @media (max-width: 700px) {
      p {
        font-size: 20px;
      }
    }
  }

  #themes {
    padding: 100px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../../../public/assets/img/conference/6.jpg) no-repeat center center;
    background-size: cover;

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;
      //color: white;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #48d950 0%, #8fff63 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    h3 {
      font-size: 25px;
      //color: white;
      line-height: 40px;
      font-weight: 600;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #48d950 0%, #8fff63 100%);
        height: 4px;
        width: 60px;
        top: 65px;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      line-height: 24px;
      margin: 30px 0;
      text-align: center;
      font-size: 22px;
    }

    .card {
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      background-color: rgba(255,255,255,0.7);

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }

      .card-body {
        padding: 20px;
      }
    }

    @media (max-width: 767px) {
      .card-wrapper {
        padding-bottom: 20px;
      }
    }
  }

  #leadership {
    padding: 60px 0;
    background: #f6f6f6;

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;
      //color: white;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #48d950 0%, #8fff63 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    h3 {
      font-size: 25px;
      line-height: 40px;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 0;
    }

    p {
      text-align: center;

      &.name {
        margin-bottom: 0;
        font-weight: 500;
      }

      &.title {
        font-weight: 500;
      }

      &.saying {
        font-style: italic;
      }
    }

    .card {
      border-width: inherit;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      background-color: rgba(255,255,255,0.7);

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }

      .card-body {
        padding: 20px;
      }
    }

    @media (max-width: 767px) {
      .card-wrapper {
        padding-bottom: 20px;
      }
    }

    .thumbnail {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  #target {
    padding: 60px 0;
    background: url(../../../../public/assets/img/conference/7.jpg) no-repeat center center;
    background-size: cover;
    color: white;

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;
      color: white;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #48d950 0%, #8fff63 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 767px) {
        &::after {
          height: 4px;
          width: 60px;
          bottom: 0;
          margin-left: -30px;
          left: 50%;
        }
      }
    }

    h3 {
      font-size: 25px;
      color: white;
      line-height: 40px;

      i {
        margin: 0 10px;
      }
    }

    .target-text {
      margin-top: 20px;
    }

    p {
      line-height: 24px;
      margin: 30px 0;
    }
  }

  #attendance {
    padding: 60px 0;

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    h4 {
      margin: 5px 0;
    }

    .space {
      height: 5px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
        height: 1px;
        width: 60px;
        margin-left: -30px;
        left: 50%;
        margin-top: 2px;
      }
    }

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    p {
      text-align: center;
      margin-bottom: 0;
    }

    #content {
      padding-top: 40px
    }

    .content-card {
      padding-bottom: 20px;
    }

    .thumbnail {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
      //padding-bottom: 100%; /* Set padding-bottom to create a square aspect ratio */

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      }

      h4 {
        color: white;
      }

      img {
        //position: absolute;
        //top: 0;
        //left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        transition: opacity 0.3s ease;
        //opacity: 0;
      }

      //&:hover .caption {
      //  opacity: 1;
      //}
    }

    .divider {
      padding: 20px;
    }
  }

  #map {
    height: 500px;
  }

  #itinerary {
    padding: 60px 0;
    background: #f6f6f6;

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
    }

    div > i {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    #buttonWrapper {
      margin-top: 40px;
    }

    a {
      text-decoration: none;
    }
  }

  #footer {
    width: 100%;
    padding: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../../../public/assets/img/conference/21.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    a {
      text-decoration: none;
    }

    .header-text {
      padding-top: 200px;
      padding-bottom: 160px;
      text-align: center;
    }

    h1 {
      font-family: $font-family_3;
      color: $color_3;
      font-size: 60px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 25px;

      span {
        font-weight: 800;
        color: $color_4;
      }
    }

    @media (max-width: 500px) {
      h1 {
        font-size: 50px;
      }
    }

    h3 {
      color: white;
      font-weight: 300;
      margin-bottom: 35px;
    }

    p {
      color: $color_3;
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      margin: 0 auto 40px;
      text-align: center;

      &.info {
        margin: 0 0 5px 0;
      }
    }

    a {
      margin-top: 20px;
    }
  }

  .copyright {
    padding-top: 10px;
  }

  @media (max-width: 1199px) {
    #conference-menu {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .navbar-header {
      float: none;
    }
    .navbar-left {
      float: none !important;
    }
    .navbar-right {
      float: none !important;
      margin-right: 0;
    }
    .navbar-toggle {
      display: block;
    }
    .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
      display: none !important;
    }
    .navbar-nav {
      float: none !important;
      margin-top: 7.5px;

      > li {
        float: none;

        > a {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    .collapse.in {
      display: block !important;
    }
  }

}

@media screen and (max-width: 400px) {
  #intro {
    width: 100%;
  }
  #about {
    width: 100%;
  }
  #credits {
    width: 100%;
  }
  #learn-more {
    width: 100%;
  }
  #contact {
    width: 100%;
  }
  #footer {
    width: 100%;
  }
}
