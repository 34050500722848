#home {
  $color_1: #00573f;
  $color_2: #555;
  $color_3: #fff;
  $color_4: #5ca9fb;
  $color_5: rgba(255, 255, 255, 0.75);
  $color_6: #cc0033;
  $color_7: black;
  $color_8: rgba(255, 255, 255, 1);
  $color_9: #608dfd;
  $color_10: #777;
  $font-family_1: "Century Gothic", sans-serif;
  $font-family_2: "Lato", sans-serif;
  $font-family_3: "Raleway", sans-serif;
  $font-family_4: "FontAwesome";
  $background-color_1: #fff;
  $background-color_2: #608dfd;
  $background-color_3: transparent;
  $background-color_4: #5ca9fb;
  $background-color_5: #6372ff;
  $background-color_6: #a2aafa;
  $border-color_1: rgba(231, 231, 231, 0);
  $border-color_2: #608dfd;

  #home-menu {
    min-width: 400px;
    transition: all 0.8s;
    top: 85px;

    a.navbar-brand {
      font-family: $font-family_1;
      font-size: 24px;
      font-weight: 700;
      color: $color_1;
    }

    .wrapper {
      text-align: center;
      padding-top: 4px;

      ul {
        float: unset;
        display: inline-block;
        margin: 0;
        padding: 0;
        zoom: 1;
        *display: inline;
      }

      li {
        float: left;
        padding: 2px 5px;
      }
    }
  }

  #home-menu.navbar-default {
    background-color: $background-color_1;
    border-color: $border-color_1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    .navbar-nav {
      > li {
        position: relative;

        > .section-button {
          --horizontal-padding: 2px;
          --horizontal-marging: 15px;
          font-family: $font-family_2;
          text-transform: uppercase;
          color: $color_2;
          font-size: 15px;
          font-weight: 400;
          border-radius: 0;
          margin: 0 var(--horizontal-marging) 0;
          text-decoration: none;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -1px;
            width: 0;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .navbar-toggle {
    border-radius: 0;
  }

  .navbar-default {
    .navbar-toggle {
      &:hover {
        background-color: $background-color_1;
        border-color: $border-color_2;

        > .icon-bar {
          background-color: $background-color_2;
        }
      }

      &:focus {
        background-color: $background-color_1;
        border-color: $border-color_2;
      }
    }

    .navbar-nav {
      .active.section-button {
        background-color: $background-color_3;

        &:hover {
          background-color: $background-color_3;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }

        &:focus {
          background-color: $background-color_3;

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }

        &:after {
          --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
          display: block;
          position: absolute;
          left: calc((100% - var(--text-width)) / 2);
          bottom: -6px;
          width: 100% !important;
          max-width: var(--text-width);
          height: 2px;
          background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
          content: "";
          transition: width 0.2s;
        }
      }

      > .main-active {
        > .section-button {
          background-color: $background-color_3;

          &:hover {
            background-color: $background-color_3;

            &:after {
              --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
              display: block;
              position: absolute;
              left: calc((100% - var(--text-width)) / 2);
              bottom: -6px;
              width: 100% !important;
              max-width: var(--text-width);
              height: 2px;
              background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
              content: "";
              transition: width 0.2s;
            }
          }

          &:focus {
            background-color: $background-color_3;

            &:after {
              --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
              display: block;
              position: absolute;
              left: calc((100% - var(--text-width)) / 2);
              bottom: -6px;
              width: 100% !important;
              max-width: var(--text-width);
              height: 2px;
              background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
              content: "";
              transition: width 0.2s;
            }
          }

          &:after {
            --text-width: calc(100% - var(--horizontal-marging) - (2 * var(--horizontal-padding)));
            display: block;
            position: absolute;
            left: calc((100% - var(--text-width)) / 2);
            bottom: -6px;
            width: 100% !important;
            max-width: var(--text-width);
            height: 2px;
            background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
            content: "";
            transition: width 0.2s;
          }
        }
      }
    }
  }

  .section-title {
    margin-bottom: 70px;

    h2 {
      position: relative;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      font-size: 18px;
    }
  }

  .btn {
    &:active {
      background-image: none;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:focus {
        outline: none;
        outline-offset: unset;
      }
    }

    &:focus {
      outline: none;
      outline-offset: unset;
    }

    &:active.focus {
      outline: none;
      outline-offset: unset;
    }
  }

  .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    &:focus {
      outline: none;
      outline-offset: unset;
    }
  }

  a {
    &:focus {
      outline: none;
      outline-offset: unset;
    }
  }

  .btn.focus {
    outline: none;
    outline-offset: unset;
  }

  .btn.active.focus {
    outline: none;
    outline-offset: unset;
  }

  div.anchor {
    display: block;
    position: relative;
    top: -236px;
    visibility: hidden;
    height: 1px;
  }

  #header {
    div.anchor {
      top: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .header {
    display: table;
    width: 100%;
    padding: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../../../public/assets/img/home/pictures/header02.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    .overlay {
      background: rgba(0, 0, 0, 0.2);
    }

    h1 {
      font-family: $font-family_3;
      color: $color_3;
      font-size: 60px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 10px;

      span {
        font-weight: 800;
        color: $color_4;
      }
    }

    @media (max-width: 500px) {
      h1 {
        font-size: 40px;
      }
    }

    @media (min-width: 501px) and (max-width: 992px) {
      h1 {
        font-size: 50px;
      }
    }

    p {
      color: $color_3;
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      margin: 0 auto 60px;
      text-align: center;
    }
  }

  header {
    .header-text {
      padding-top: 350px;
      padding-bottom: 270px;
      text-align: center;
    }
  }

  #intro {
    padding: 100px 0;
    background: #f6f6f6;

    h3 {
      font-size: 22px;
      margin: 0 0 20px;
    }

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    p {
      line-height: 24px;
      margin: 30px 0;
    }
  }

  #about {
    padding: 100px 0;

    h3 {
      font-size: 22px;
      margin: 0 0 20px;
    }

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        left: 0;
      }
    }

    h2.centered-title {
      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    .about-text {
      li {
        margin-bottom: 6px;
        margin-left: 6px;
        list-style: none;
        padding: 0;

        &:before {
          content: "\f00c";
          font-family: $font-family_4;
          color: $color_4;
          font-size: 11px;
          font-weight: 300;
          padding-right: 8px;
        }
      }
    }

    img {
      margin: 20px auto;
      background: #fff;
      border-right: 0;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
    }

    p {
      line-height: 24px;
      margin: 30px 0;
    }
  }

  #credits {
    padding: 100px 0;
    background: #f6f6f6;

    h3 {
      font-size: 22px;
      margin: 0 0 20px;
    }

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        left: 0;
      }
    }

    h2.centered-title {
      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    .about-text {
      li {
        margin-bottom: 6px;
        margin-left: 6px;
        list-style: none;
        padding: 0;

        &:before {
          content: "\f00c";
          font-family: $font-family_4;
          color: $color_4;
          font-size: 11px;
          font-weight: 300;
          padding-right: 8px;
        }
      }
    }

    img {
      margin: 20px auto;
      background: #fff;
      border-right: 0;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
    }

    p {
      line-height: 24px;
      margin: 30px 0;
    }
  }

  #learn-more {
    padding: 100px 0;

    div {
      h3 {
        font-size: 22px;
        margin: 0 0 20px 20px;
      }
    }

    a {
      h3 {
        font-size: 22px;
        margin: 0;
      }
    }

    h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        left: 0;
      }
    }

    h2.centered-title {
      &::after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        margin-left: -30px;
        left: 50%;
      }
    }

    .about-text {
      li {
        margin-bottom: 6px;
        margin-left: 6px;
        list-style: none;
        padding: 0;

        &:before {
          content: "\f00c";
          font-family: $font-family_4;
          color: $color_4;
          font-size: 11px;
          font-weight: 300;
          padding-right: 8px;
        }
      }
    }

    img {
      margin: 20px auto;
      background: #fff;
      border-right: 0;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
      max-height: 450px;
    }

    p {
      line-height: 24px;
      margin: 20px 20px;
    }
  }

  #contact {
    padding: 100px 0 60px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    color: $color_5;

    .section-title {
      margin-bottom: 10px;

      p {
        font-size: 16px;
      }

      h2 {
        &::after {
          position: absolute;
          content: "";
          background: rgba(255, 255, 255, 0.3);
          height: 4px;
          width: 60px;
          bottom: 0;
          left: 30px;
        }
      }
    }

    h2 {
      color: $color_3;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    h3 {
      color: $color_3;
      margin-top: 80px;
      margin-bottom: 25px;
      padding-bottom: 20px;
      font-weight: 400;
      font-size: 20px;
    }

    .text-danger {
      color: $color_6;
      text-align: left;
    }

    form {
      margin-bottom: 10px;
    }

    .form-wrapper {
      background-color: $background-color_1;
      border-radius: 25px;
      padding: 15px;

      .form-label {
        color: $color_7;
      }

      .form-group {
        padding-top: 10px;
      }
    }

    .contact-item {
      margin: 20px 0;

      span {
        color: $color_8;
        margin-bottom: 10px;
        display: block;
      }

      i.fa {
        margin-right: 10px;
      }
    }

    .social {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      padding-top: 50px;
      margin-top: 50px;
      text-align: center;

      ul {
        li {
          display: inline-block;
          margin: 0 20px;
        }
      }

      i.fa {
        font-size: 22px;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border: 2px solid #fff;
        color: $color_3;
        border-radius: 50%;
        transition: all 0.3s;

        &:hover {
          color: $color_9;
          background: #fff;
        }
      }
    }
  }

  .copyright {
    padding-top: 10px;
  }

  .form-control {
    &::-webkit-input-placeholder {
      color: $color_10;
    }

    &:-moz-placeholder {
      color: $color_10;
    }

    &::-moz-placeholder {
      color: $color_10;
    }

    &:-ms-input-placeholder {
      color: $color_10;
    }
  }

  @media (max-width: 1199px) {
    #home-menu {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .navbar-header {
      float: none;
    }
    .navbar-left {
      float: none !important;
    }
    .navbar-right {
      float: none !important;
      margin-right: 0;
    }
    .navbar-toggle {
      display: block;
    }
    .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
      display: none !important;
    }
    .navbar-nav {
      float: none !important;
      margin-top: 7.5px;

      > li {
        float: none;

        > a {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    .collapse.in {
      display: block !important;
    }
  }
  @media (max-width: 767px) {
    #contact {
      .center-text-md {
        text-align: center !important;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #intro {
    width: 100%;
  }
  #about {
    width: 100%;
  }
  #credits {
    width: 100%;
  }
  #learn-more {
    width: 100%;
  }
  #contact {
    width: 100%;
  }
  #footer {
    width: 100%;
  }
}
