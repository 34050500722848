#enroll {
  $color_1: #00573f;
  $color_2: #555;
  $color_3: #fff;
  $color_4: #5ca9fb;
  $color_5: rgba(255, 255, 255, 0.75);
  $color_6: #cc0033;
  $color_7: black;
  $color_8: rgba(255, 255, 255, 1);
  $color_9: #608dfd;
  $color_10: #777;
  $font-family_1: "Century Gothic", sans-serif;
  $font-family_2: "Lato", sans-serif;
  $font-family_3: "Raleway", sans-serif;
  $font-family_4: "FontAwesome";
  $background-color_1: #fff;
  $background-color_2: #608dfd;
  $background-color_3: transparent;
  $background-color_4: #5ca9fb;
  $background-color_5: #6372ff;
  $background-color_6: #a2aafa;
  $border-color_1: rgba(231, 231, 231, 0);
  $border-color_2: #608dfd;

  #enroll-contact {
    padding: 100px 0 60px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    color: $color_5;

    .section-title {
      margin-bottom: 10px;

      p {
        font-size: 16px;
      }

      h2 {
        &::after {
          position: absolute;
          content: "";
          background: rgba(255, 255, 255, 0.3);
          height: 4px;
          width: 60px;
          bottom: 0;
          left: 30px;
        }
      }
    }

    h2 {
      color: $color_3;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    h3 {
      color: $color_3;
      margin-top: 80px;
      margin-bottom: 25px;
      padding-bottom: 20px;
      font-weight: 400;
      font-size: 20px;
    }

    .text-danger {
      color: $color_6;
      text-align: left;
    }

    form {
      margin-bottom: 10px;
    }

    .form-wrapper {
      background-color: $background-color_1;
      border-radius: 25px;
      padding: 15px;

      .form-group {
        padding-top: 10px;
      }

      .form-label {
        color: $color_7;
      }

      .form-check-label {
        color: $color_7;
      }
    }

    .contact-item {
      margin: 20px 0;

      span {
        color: $color_8;
        margin-bottom: 10px;
        display: block;
      }

      i.fa {
        margin-right: 10px;
      }
    }

    .social {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      padding-top: 50px;
      margin-top: 50px;
      text-align: center;

      ul {
        li {
          display: inline-block;
          margin: 0 20px;
        }
      }

      i.fa {
        font-size: 22px;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border: 2px solid #fff;
        color: $color_3;
        border-radius: 50%;
        transition: all 0.3s;

        &:hover {
          color: $color_9;
          background: #fff;
        }
      }
    }
  }

  .form-control.is-invalid, .form-control.is-invalid:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  }

  .form-control {
    &::-webkit-input-placeholder {
      color: $color_10;
    }

    &:-moz-placeholder {
      color: $color_10;
    }

    &::-moz-placeholder {
      color: $color_10;
    }

    &:-ms-input-placeholder {
      color: $color_10;
    }
  }

  @media (max-width: 767px) {
    #enroll-contact {
      .center-text-md {
        text-align: center !important;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #intro {
    width: 100%;
  }
  #about {
    width: 100%;
  }
  #credits {
    width: 100%;
  }
  #learn-more {
    width: 100%;
  }
  #enroll-contact {
    width: 100%;
  }
  #footer {
    width: 100%;
  }
}
