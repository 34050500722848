$color_1: #fff;
$font-family_1: "Raleway", sans-serif;
$background-color_1: #5ca9fb;
$background-color_2: #6372ff;
$background-color_3: #a2aafa;

.flex {
  display: flex;
}

.header-logo {
  max-height: 55px;
  >img {
    height: 55px;
    width: 60px;
    padding-right: 5px;
  }
}

.btn-custom {
  font-family: $font-family_1;
  text-transform: uppercase;
  color: $color_1;
  background-color: $background-color_1;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 20px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;

  &:hover {
    color: $color_1;
    background-image: none;
    background-color: $background-color_2;
  }

  &:focus {
    color: $color_1;
    background-image: none;
    background-color: $background-color_2;
  }

  &:active {
    color: $color_1;
    background-image: none;
    background-color: $background-color_2;
  }

  &:disabled {
    background-color: $background-color_3;
    background-image: none;
  }
}

.btn-custom.focus {
  color: $color_1;
  background-image: none;
  background-color: $background-color_2;
}

.btn-custom.active {
  color: $color_1;
  background-image: none;
  background-color: $background-color_2;
}
