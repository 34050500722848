$color_1: #00573f;
$color_2: #555;
$color_3: #fff;
$color_4: #5ca9fb;
$color_5: rgba(255, 255, 255, 0.75);
$color_6: #cc0033;
$color_7: black;
$color_8: rgba(255, 255, 255, 1);
$color_9: #608dfd;
$color_10: #777;
$font-family_1: "Century Gothic", sans-serif;
$font-family_2: "Lato", sans-serif;
$font-family_3: "Raleway", sans-serif;
$font-family_4: "FontAwesome";
$background-color_1: #fff;
$background-color_2: #608dfd;
$background-color_3: transparent;
$background-color_4: #5ca9fb;
$background-color_5: #6372ff;
$background-color_6: #a2aafa;
$border-color_1: rgba(231, 231, 231, 0);
$border-color_2: #608dfd;

.search-form {
  padding: 20px;
  max-height: 700px;
  max-width: 900px;
  overflow-y: auto;
  transition: all 0.8s;

  .form-group {
    padding-top: 10px;
  }

  .form-check {
    .form-label {
      color: $color_7 !important;
    }

    .form-check-label {
      color: $color_7 !important;
    }
  }

  .inline {
    display: flex;
    align-items: center;
  }

  .inline > button {
    margin-left: 10px;
  }

  .table-pagination {
    margin-bottom: 0;
  }

  .form-control.is-invalid, .form-control.is-invalid:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  }
}

#preview-modal {
  max-width: 1100px;

  .certification-preview-img {
    width: 100%;
  }
}
