body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.content {
  flex: 1;
  margin-bottom: 200px;
  background: transparent;
}

.certifications-background {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../../assets/imgs/Agriculture01.jpg) no-repeat center center;

  @media (min-width: 767px) {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

#certifications {
  #certifications-container {
    position: relative;
    top: 87px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;
    flex-grow: 1;

    .background-transp {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }

    .search-wrapper {
      border-radius: 15px !important;
    }

    .search-form-title {
      font-size: 20px;
      font-weight: 500;
    }

    .low-brightness {
      filter: brightness(0.9);
      transition: all 0.5s ease;
    }

    .copyright {
      padding-top: 10px;
    }
  }

  #footer {
    background-color: #f8f9fa;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    height: 45px;
  }
}
